/* Change this file to get your personal Portfolio */

// To change portfolio colors globally go to the  _globalColor.scss file

import emoji from "react-easy-emoji";
import splashAnimation from "./assets/lottie/splashAnimation"; // Rename to your file name for custom animation

// Splash Screen

const splashScreen = {
  enabled: true, // set false to disable splash screen
  animation: splashAnimation,
  duration: 2000 // Set animation duration as per your animation
};

// Summary And Greeting Section

const illustration = {
  animated: true // Set to false to use static SVG
};

const greeting = {
  username: "Yi Chen",
  title: "Hello, I'm Yi Chen",
  subTitle: 
    ["A", "p","a","s","s","i","o","n","a","t","e", "F", "u", "l", "l", " ", "S", "t", "a", "c", "k", " ", "S", "o", "f", "t", "w", "a", "r", "e", " ", "E", "n", "g", "i", "n", "e", "e", "r", ".", " ", "E", "x", "p", "e", "r", "i", "e", "n", "c", "e", "d", " ", "i", "n", " ", "P", "r", "o", "g", "r", "a", "m", "m", "i", "n", "g", " ", "&", " ", "B", "u", "i", "l", "d", "i", "n", "g", " ", "F", "u", "l", "l", " ", "S", "t", "a", "c", "k", " ", "A", "p", "p", "l", "i", "c", "a", "t", "i", "o", "n", "s", " ", "w", "i", "t", "h", " ", "J", "a", "v", "a", "S", "c", "r", "i", "p", "t", " ", "/", " ", "R", "e", "a", "c", "t", "j", "s", " ", "/", " ", "N", "e", "x", "t", "j", "s", " ", "/", " ", "N", "o", "d", "e", "j", "s", " ", "/", " ", "P", "y", "t", "h", "o", "n", " ", "/", " ", "F", "l", "a", "s", "k", " ", "/", " ", "D", "j", "a", "n", "g", "o", "/", " ", "P", "r", "i", "s", "m", "a", " ", "/", " ", "P", "o", "s", "t", "g", "r", "e", "s", " ", "/", " ", "M", "y", "S", "Q", "L", "."]
  ,
  resumeLink: "", // Set to empty to hide the button
  displayGreeting: true // Set false to hide this section, defaults to true
};

// Social Media Links

const socialMediaLinks = {
  github: "https://github.com/heehyun1128",
  linkedin: "https://www.linkedin.com/in/yi-c-452811132/",
  gmail: "chenyiapp@berkeley.edu",

  display: true // Set true to display this section, defaults to false
};

// Skills Section

const skillsSection = {
  title: "MY SKILLS",
  subTitle: "INDEPENDENT PROBLEM-SOLVER & COLLABORATIVE FULL STACK DEVELOPER & FAST-LEARNER & EXPERIENCE IN AI APPLICATION DEVELOPMENT",
  skills: [
    emoji(
      "⚡ Passionate About Integrating AI Into Cutting-Edge Software Solutions"
    ),
    emoji(
      "⚡ Develop Highly Interactive Front End / User Interfaces For Your Web Applications"
    ),
    emoji("⚡ Design & Development of Database and API"),
    emoji("⚡ Integration of third party services such as Firebase/ AWS ")
  ],

  softwareSkills: [
    {
      skillName: "JavaScript",
      fontAwesomeClassname: "fab fa-js",
      color: "#F7DF1E"
    },
    {
      skillName: "TypeScript",
      fontAwesomeClassname: "devicon-typescript-original colored"
    },
    {
      skillName: "Python",
      fontAwesomeClassname: "fab fa-python",
      color: "#3776AB"
    },
    {
      skillName: "NextJS",
      fontAwesomeClassname: "devicon-nextjs-original-wordmark colored"
    },
    {
      skillName: "ReactJS",
      fontAwesomeClassname: "fab fa-react",
      color: "#61DAFB"
    },
    {
      skillName: "Redux",
      fontAwesomeClassname: "devicon-redux-original colored"
    },
    {
      skillName: "NodeJS",
      fontAwesomeClassname: "fab fa-node",
      color: "#339933"
    },
    {
      skillName: "ExpressJS",
      fontAwesomeClassname: "fab fa-node-js", // Example class name (adjust as needed)
      color: "orange" // Adjust color as needed
    },
    {
      skillName: "Prisma",
      fontAwesomeClassname: "devicon-prisma-original colored"
    },
    {
      skillName: "Sequelize",
      fontAwesomeClassname: "devicon-sequelize-plain colored"
    },
    {
      skillName: "Flask",
      fontAwesomeClassname: "devicon-flask-original ",
      color: "#16b0c4"
    },
    {
      skillName: "SQLAlchemy",
      fontAwesomeClassname: "devicon-sqlalchemy-plain colored"
    },
    {
      skillName: "Django",
      fontAwesomeClassname: "devicon-django-plain",
      color: "#054d17"
    },
    {
      skillName: "Axios",
      fontAwesomeClassname: "devicon-axios-plain-wordmark colored"
    },
    {
      skillName: "Postgres",
      fontAwesomeClassname: "devicon-postgresql-plain-wordmark colored",
      color: "#336791"
    },
    {
      skillName: "Mysql",
      fontAwesomeClassname: "devicon-mysql-plain-wordmark colored",
      color: "#4479A1"
    },
    {
      skillName: "MongoDB",
      fontAwesomeClassname: "devicon-mongodb-plain colored"
    },
    {
      skillName: "Mongoose",
      fontAwesomeClassname: "devicon-mongoose-original-wordmark colored"
    },
    {
      skillName: "Figma",
      fontAwesomeClassname: "devicon-figma-plain colored"
    },
    {
      skillName: "HTML-5",
      fontAwesomeClassname: "fab fa-html5",
      color: "#E34F26"
    },
    {
      skillName: "CSS3",
      fontAwesomeClassname: "fab fa-css3-alt",
      color: "#1572B6"
    },
    {
      skillName: "Grafana",
      fontAwesomeClassname: "devicon-grafana-plain-wordmark colored"
    },

    {
      skillName: "MaterialUI",
      fontAwesomeClassname: "devicon-materialui-plain colored"
    },

    {
      skillName: "TailwindCSS",
      fontAwesomeClassname: "devicon-tailwindcss-plain colored"
    },
    {
      skillName: "Bootstrap",
      fontAwesomeClassname: "devicon-bootstrap-plain colored"
    },
    {
      skillName: "Java",
      fontAwesomeClassname: "devicon-java-plain colored"
    },
    {
      skillName: "Visual Basic",
      fontAwesomeClassname: "devicon-visualbasic-plain colored"
    },
    {
      skillName: "JQuery",
      fontAwesomeClassname: "devicon-jquery-plain colored"
    },

    {
      skillName: "Sass",
      fontAwesomeClassname: "fab fa-sass",
      color: "#CC6699"
    },

    {
      skillName: "NPM",
      fontAwesomeClassname: "fab fa-npm",
      color: "#CB3837"
    },
    {
      skillName: "GIT",
      fontAwesomeClassname: "devicon-git-plain-wordmark colored"
    },
    {
      skillName: "Postman",
      fontAwesomeClassname: "devicon-postman-plain colored"
    },
    {
      skillName: "Aws",
      fontAwesomeClassname: "fab fa-aws",
      color: "#FF9900"
    },
    {
      skillName: "firebase",
      fontAwesomeClassname: "fas fa-fire",
      color: "#FFCA28"
    },

    {
      skillName: "Docker",
      fontAwesomeClassname: "fab fa-docker",
      color: "#2496ED"
    },

    {
      skillName: "VSCode",
      fontAwesomeClassname: "devicon-vscode-plain-wordmark colored"
    }
  ],
  display: true // Set false to hide this section, defaults to true
};

// Education Section

const educationInfo = {
  display: true, // Set false to hide this section, defaults to true
  schools: [
    {
      schoolName: "University of California, Berkeley",
      logo: require("./assets/images/uc_berkeley_logo.jpeg"),
      subHeader: "Master of Science - Engineering & Project Management",
      duration: "August 2016 - May 2017",
      desc: "ACHIEVEMENTS - 2016 Berkeley CEE Department Scholarship",
      descBullets: ["Engineering | Project Management | Lean Technologies"],
      image:"https://images.unsplash.com/photo-1579748138140-ce9ef2c32db1?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=634&q=80"
    },
   
    {
      schoolName: "California Institute of Advanced Management",
      logo: require("./assets/images/ciam.webp"),
      subHeader: "MBA",
      duration: "May 2020 - April 2022",
      desc: "ACHIEVEMENTS - Four Times on the Dean's List",
      descBullets: [
        "Finance | Marketing | Communications | Entrepreneurship"
      ]
    },
    {
      schoolName: "Zhengzhou University",
      logo: require("./assets/images/zzu.png"),
      subHeader: "Civl - Water Resources & Hydropower Engineering",
      duration: "August 2011 - June 2015",
      desc: "ACHIEVEMENTS - Provincial & School Honor Student & Scholarships",
      descBullets: [
        "Computer Basics | Programming with Visual Basic | Linear Algebra | Calculus"
      ]
    },
  ]
};

// Your top 3 proficient stacks/tech experience

const techStack = {
  viewSkillBars: true, //Set it to true to show Proficiency Section
  experience: [
    {
      Stack: "Frontend - JavaScript / React.js / Next.js ", //Insert stack or technology you have experience in
      progressPercentage: "90%" //Insert relative proficiency in percentage
    },
    {
      Stack: "Backend & API - Node.js / Python / Flask / Django / Prisma",
      progressPercentage: "90%"
    },
    {
      Stack: "Databases - Postgres, MySQL, MongoDB",
      progressPercentage: "80%"
    },
    {
      Stack: "UI Design - MaterialUI / TailwindCSS / Bootstrap", //Insert stack or technology you have experience in
      progressPercentage: "90%" //Insert relative proficiency in percentage
    },
    {
      Stack: "OOP Programming",
      progressPercentage: "70%"
    }
  ],
  displayCodersrank: false // Set true to display codersrank badges section need to changes your username in src/containers/skillProgress/skillProgress.js:17:62, defaults to false
};

// Work experience section

const workExperiences = {
  display: true, //Set it to true to show workExperiences Section
  experience: [
    {
      role: "Software Engineer",
      company: "Canvas",
      companylogo: require("./assets/images/canvas.png"),
      date: "February - June 2024",
      desc: "Next.js 14 | TypeScript | PostgreSQL | Prisma ORM | Zod | Axios | Figma | MaterialUI | FullCalendar | Grafana",
      descBullets: [
        "Led the development and deployment of a comprehensive fleet management software",
        "Independently developed performance metrics and Grafana Dashboards to monitor machine operational issues and productivity"
       
      ],
      image:"https://images.unsplash.com/photo-1579310962131-aa21f240d986?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1234&q=80"
    
    },
    {
      role: "Software Developer In Training",
      company: "App Academy",
      companylogo: require("./assets/images/Appacademylogo.png"),
      date: "May - October 2023",
      desc: "JavaScript | React | Redux | Postgres | Python | Flask | Express | AWS",
      descBullets: ["ACHIEVEMENTS - 90%+ with zero deferrals | Ranked 1st in Cohort","Developed a Full Stack Online social platform for information sharing, discussions, and connections.", 
      "UI/UX Design in Figma (90%), Tag feature (80%), Search feature (95%) for a Full Stack Online Retail Platform Selling Pet Products."],
      image:"https://images.unsplash.com/photo-1579310962131-aa21f240d986?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1234&q=80"
    
    },
    {
      role: "Software Developer ",
      company: "Freelance Software Developer",
      companylogo: require("./assets/images/freelance.png"),
      date: "June 2022 – April 2023",
      desc: "Python3 | JavaScript ES5/ES6+ | MERN Stack | RESTful API | SQL/NoSQL Databases |  jQuery | Ajax | Axios | HTML | CSS | PHP | OOP | GUI | Pandas",
      descBullets: ["Developed a Role-based Organization Management App using MERN Stack.", 
      "Built the frontend/UI for a Online Designer Fashion Store"],
      image:"https://images.unsplash.com/photo-1579310962131-aa21f240d986?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1234&q=80"
    
    
    },
    {
      role: "Project Engineer",
      company: "Giampolini",
      companylogo: require("./assets/images/giampolini.png"),
      date: "October 2017 – December 2020",
      desc: "Visual Basic for Application | Miscrosoft Access | Project Management",
      descBullets: [ 
      "Independently designed and developed a Purchase Order Generation and Tracking System using VBA (Microsoft Visual Basic), enforcing data collection, validation, analyzation, and visualization.",
      "Project Management & Planning"],
      image:"https://images.unsplash.com/photo-1579310962131-aa21f240d986?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1234&q=80"
    
    
    },
    {
      role: "Project Engineer",
      company: "Innovative Project Solutions",
      companylogo: require("./assets/images/ips.jpeg"),
      date: "March 2021 – November 2021",
      desc: "Project QA/QC | Project Management, Planning & Scheduling",
      descBullets: [ 
      "Led the QA/QC and cost estimation of project documents"],
      image:"https://images.unsplash.com/photo-1579310962131-aa21f240d986?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1234&q=80"
    
    
    },
   
  ]
};

/* Your Open Source Section to View Your Github Pinned Projects
To know how to get github key look at readme.md */

const openSource = {
  showGithubProfile: "true", // Set true or false to show Contact profile using Github, defaults to true
  display: true // Set false to hide this section, defaults to true
};

// Some big projects you have worked on

const bigProjects = {
  title: "PROJECTS",
  subtitle: "Full Stack & AI Applications",
  projects: [
    {
      image: require("./assets/images/moment.gif"),
      projectName: "Moment",
      projectDesc: "Full-stack Online social platform with AI content creation",
      stack:"React | Redux | Python | JavaScript | Flask | SQLAlchemy | AWS S3 | PostgreSQL | OpenAI",
      footerLink: [
        {
          name: "Visit Website",
          url: "https://moment-2igd.onrender.com/"
        },
        {
          name: "Visit Github",
          url: "https://github.com/heehyun1128/yc-Moment"
        },
        //  you can add extra buttons here.
      ]
    },
    {
      image: require("./assets/images/petsy.gif"),
      projectName: "Petsy",
      projectDesc: "Full-stack E-Commerce Platform.",
      stack:"React | Redux | Python | JavaScript | Flask | SQLAlchemy | CSS | PostgreSQL | Figma",
      footerLink: [
        {
          name: "Visit Website",
          url: "https://petsy-kn3b.onrender.com/"
        },
        {
          name: "Visit Github",
          url: "https://github.com/nickhosman/Petsy"
        },
       
      ]
    },
    
    {
      image: require("./assets/images/echo.gif"),
      projectName: "Echo - Document Manager",
      projectDesc: "AI-powered Construction Document Manager Through Chats. Currently under development",
      stack:"NextJS | TypeScript | Hugging Face | Pinecone | Google Gemini | Speech to Text ",
      footerLink: [
        {
          name: "View Website",
          url: "https://www.project-echo.us/"
        },
      
       
      ]
    },
    {
      image: require("./assets/images/garry.gif"),
      projectName: "Garry AI - Rate My Professor",
      projectDesc: "AI-powered Rate My Professor Platform",
      stack:"NextJS | TypeScript | Pinecone | OpenAI | Sentiment Analysis | Playwright ",
      footerLink: [
        {
          name: "View Demo",
          url: "https://youtu.be/OlWGVpPpbb4"
        },
        {
          name: "Visit Github",
          url: "https://github.com/heehyun1128/rate_my_professor"
        },
       
      ]
    },
    {
      image: require("./assets/images/chatter.gif"),
      projectName: "Chatter AI",
      projectDesc: "Full-stack AI-powered Chat Assistant",
      stack:"Retrieval-Augmented Generation (RAG) | Pinecone | LLMs - Claude, LLama, OpenAI | AWS Bedrock | AWS EC2 | NextJS | TypeScript | Python | Flask",
      footerLink: [
        {
          name: "View Demo",
          url: "https://www.youtube.com/watch?v=jKylYEVI1sM"
        },
        {
          name: "Visit Github",
          url: "https://github.com/heehyun1128/Chatters"
        },
       
      ]
    },
    {
      image: require("./assets/images/cardia.gif"),
      projectName: "Cardia AI",
      projectDesc: "Full-stack SaaS AI-powered Flashcard Creation Platform",
      stack:"NextJS | TypeScript | Clerk | Stripe | OpenAI | Firebase",
      footerLink: [
        {
          name: "View Demo",
          url: "https://www.youtube.com/watch?v=Fv0U_stcL0o"
        },
        {
          name: "Visit Github",
          url: "https://github.com/heehyun1128/flashcard"
        },
       
      ]
    },
    {
      image: require("./assets/images/inventa.gif"),
      projectName: "Inventa",
      projectDesc: "Full-stack AI-powered Inventory Management System",
      stack:"NextJS | Firebase | TypeScript | OPENAI GPT Vision | Vercel CI/CD | React Camera Pro | Material UI",
      footerLink: [
        {
          name: "Visit Website",
          url: "https://inventa-v8of.vercel.app/"
        },
        {
          name: "Visit Github",
          url: "https://github.com/heehyun1128/Inventa"
        },
       
      ]
    },
    {
      image: require("./assets/images/gogoal.gif"),
      projectName: "Go Goal",
      projectDesc: "Full-stack AI-powered Goal Management App",
      stack:"OpenAI | NextJS | JavaScript | MongoDB | Python | Flask | Vercel CI/CD | Tailwind CSS",
      footerLink: [
        {
          name: "View Demo",
          url: "https://youtu.be/pCFSJ4_Weyk"
        },
        {
          name: "Visit Github",
          url: "https://github.com/heehyun1128/goal_tracker"
        },
       
      ]
    },
    {
      image: require("./assets/images/rulla.gif"),
      projectName: "Rulla",
      projectDesc: "Full-stack AI-powered sales transcript note-taking and file management system",
      stack:"AWS DynamoDB | AWS Lambda | AWS API Gateway | AWS S3 | NextJS | OpenAI",
      footerLink: [
        {
          name: "View Demo",
          url: "https://youtu.be/M1iIi9_NeAw"
        },
        {
          name: "Visit Github",
          url: "https://github.com/heehyun1128/rulla"
        },
       
      ]
    },
   
    {
      image: require("./assets/images/picto.gif"),
      projectName: "Picto",
      projectDesc: "Full-stack Saas AI Photo-Processing Application",
      stack:"NextJS | TypeScript | MongoDB | Mongoose | Stripe | Clerk | CloudinaryAI | OpenAI",
      footerLink: [
        {
          name: "Visit Website",
          url: "https://picto-silk.vercel.app/"
        },
        {
          name: "Visit Github",
          url: "https://github.com/heehyun1128/Picto"
        },
       
      ]
    },
    {
      image: require("./assets/images/thinkey.gif"),
      projectName: "Thinkey",
      projectDesc: "Full-stack AI Chat-Image-Video Platform.",
      stack:"NextJS | TypeScript | OpenAI API | Clerk | Tailwind CSS",
      footerLink: [
        {
          name: "Visit Website",
          url: "https://thinkey-cvjyau33x-heehyun1128s-projects.vercel.app/"
        },
        {
          name: "Visit Github",
          url: "https://github.com/heehyun1128/Thinkey"
        },
      ]
    },
    {
      image: require("./assets/images/suju.png"),
      projectName: "SUJU",
      projectDesc: "Frontend Design for an Online Designer Fashion Store",
      stack:"JavaScript DOM | Express | HTML | CSS | Bootstrap | MailChimp API",
      footerLink: [
        {
          name: "Visit Website",
          url: "https://suju-fashion-9bfee9186406.herokuapp.com/"
        },
      
      ]
    },
    {
      image: require("./assets/images/meetfriends.gif"),
      projectName: "MeetFriends",
      projectDesc: "A Full-stack MeetUp Clone Project",
      stack:"React | Redux/Redux-thunk | HTML 5 | CSS | Javascript | Nodejs/Express |Sequelize ORM | PostgreSQL",
      footerLink: [
        {
          name: "Visit Website",
          url: "https://meetup-backend-witc.onrender.com/"
        },
        {
          name: "Visit Github",
          url: "https://github.com/heehyun1128/MeetFriends"
        },
      ]
    },
    {
      image: require("./assets/images/meedyia.gif"),
      projectName: "Meedyia",
      projectDesc: "A Video Streaming Website",
      stack:"React | JavaScript | RapidAPI | MaterialUI",
      footerLink: [
        {
          name: "Visit Website",
          url: "https://fascinating-halva-6b93a2.netlify.app/"
        },
      
      ]
    },
    // {
    //   image: require("./assets/images/snake.gif"),
    //   projectName: "Snake Game",
    //   projectDesc: "",
    //   stack:"Python | Turtle Graphics",
    //   footerLink: [
       
    //     {
    //       name: "Visit Github",
    //       url: "https://github.com/heehyun1128/Snake-Game"
    //     },
    //   ]
    // },
    // {
    //   image: require("./assets/images/littlelemon.png"),
    //   projectName: "Little Lemon Restraurant",
    //   projectDesc: "",
    //   stack:"Python3, Django DRF, MySQL, React, CSS, JavaScript, Djoser, Insomnia",
    //   footerLink: [
       
    //     {
    //       name: "Visit Github",
    //       url: "https://github.com/heehyun1128/littlelemon_cap"
    //     },
    //   ]
    // },
  ],
  display: true // Set false to hide this section, defaults to true
};

// Achievement Section
// Include certificates, talks etc

const achievementSection = {
  title: emoji("CERTIFCATIONS"),
  subtitle:
    "Professional Certifications in Software Engineering & Agile Project Management",

  achievementsCards: [
    {
      title: "Meta Back-End Developer Professional Certificate",
      subtitle:
        "Databases for Back-End Development | APIs | Django | DjangoDRF | Python | Version Control",
      image: require("./assets/images/meta.png"),
      imageAlt: "Meta Back-End Developer Professional Certificate",
      footerLink: [
       
        {
          name: "View Meta Back-End Developer Certificate",
          url: "https://coursera.org/share/5e95e76bbcabbe4ef2338937d88ea487"
        },
      
      ]
    },
    {
      title: "Meta Front-End Developer Professional Certificate",
      subtitle:
        "React | HTML | CSS | JavaScript | UX/UI Design ",
      image: require("./assets/images/meta.png"),
      imageAlt: "Meta Front-End Developer Professional Certificate",
      footerLink: [
        {
          name: "View Meta Front-End Developer Certificate",
          url: "https://coursera.org/share/64f8e1438e4e621d85372a571dc77811"
        },
       
      
      ]
    },
    {
      title: "Object-Oriented Data Structures in C++",
      subtitle:
        "C++ Class, Memory Model, Software Solutions.",
      image: require("./assets/images/UIUC.png"),
      imageAlt: "UIUC Logo",
      footerLink: [
        {
          name: "View Certificate",
          url: "https://coursera.org/share/c2581c70a394d30ae08f31242393b3f0"
        }
      ]
    },

    {
      title: "Project Management Professional (PMP)®",
      subtitle: "Agile Project Management",
      image: require("./assets/images/PMP.png"),
      imageAlt: "PMP Logo",
      footerLink: [
     
        {
          name: "View PMP Certification",
          url: "https://drive.google.com/file/d/1VcYRpFWPoIuSakdcxnPleeTSzJH1U05N/view?usp=sharing"
        }
      ]
    },
    {
      title: "The complete 2023 Web Development Bootcamp",
      subtitle: "MongoDB, Mongoose, ExpressJS, React, JavaScript/NodeJS, jQuery, Bootstrap, HTML, CSS",
      image: require("./assets/images/udemy.jpg"),
      imageAlt: "Udemy Certificate Logo",
      footerLink: [
     
        {
          name: "View Certification",
          url: "https://www.udemy.com/certificate/UC-fc8dcda5-275d-4dc2-bd9a-0a9da741e03e/"
        }
      ]
    },
  ],
  display: true // Set false to hide this section, defaults to true
};

// Blogs Section

const blogSection = {
  title: "AWARDS",
  subtitle:
    "",
  displayMediumBlogs: "true", // Set true to display fetched medium blogs instead of hardcoded ones
  blogs: [
    {
      url: "https://www.linkedin.com/in/yi-c-452811132/details/honors/",
      title: "Berkeley CEE Department Scholarship",
      description:"2016"
    },
    {
      url: "https://www.linkedin.com/in/yi-c-452811132/details/honors/",
      title: "School’s Honor Student",
      description:"2015"
    },
    {
      url: "https://www.linkedin.com/in/yi-c-452811132/details/honors/",
      title: "Second Class School Scholarship",
      description:"2015"
    },
    {
      url: "https://www.linkedin.com/in/yi-c-452811132/details/honors/",
      title: "Provincial Honor Student with Excellent Performance",
      description:"2014"
    },
    {
      url: "https://www.linkedin.com/in/yi-c-452811132/details/honors/",
      title: "First Class School Scholarship",
      description:"2014"
    },
    {
      url: "https://www.linkedin.com/in/yi-c-452811132/details/honors/",
      title: "School’s Honor Student",
      description:"2014"
    },
    {
      url: "https://www.linkedin.com/in/yi-c-452811132/details/honors/",
      title: "Third Class Award of National English Competition for College Students",
      description:"2014"
    },
    {
      url: "https://www.linkedin.com/in/yi-c-452811132/details/honors/",
      title: "School’s Honor Student",
      description:"2013"
    },
    {
      url: "https://www.linkedin.com/in/yi-c-452811132/details/honors/",
      title: "Second Class School Scholarship",
      description:"2013"
    },
    {
      url: "https://www.linkedin.com/in/yi-c-452811132/details/honors/",
      title: "Excellent Prize in School's Singing Contest",
      description:"2012"
    },
    {
      url: "https://www.linkedin.com/in/yi-c-452811132/details/honors/",
      title: "School’s Excellent Student ",
      description:"2012"
    },
    {
      url: "https://www.linkedin.com/in/yi-c-452811132/details/honors/",
      title: "Third Class School Scholarship",
      description:"2012"
    },
    
  ],
  display: true // Set false to hide this section, defaults to true
};

// Talks Sections

const talkSection = {
  title: "TALKS",
  subtitle: emoji(
    "I LOVE TO SHARE MY LIMITED KNOWLEDGE AND GET A SPEAKER BADGE 😅"
  ),

  talks: [
    {
      title: "Build Actions For Google Assistant",
      subtitle: "Codelab at GDG DevFest Karachi 2019",
      slides_url: "https://bit.ly/saadpasta-slides",
      event_url: "https://www.facebook.com/events/2339906106275053/"
    }
  ],
  display: true // Set false to hide this section, defaults to true
};

// Podcast Section

const podcastSection = {
  title: emoji("Podcast 🎙️"),
  subtitle: "I LOVE TO TALK ABOUT MYSELF AND TECHNOLOGY",

  // Please Provide with Your Podcast embeded Link
  podcast: [
    "https://anchor.fm/codevcast/embed/episodes/DevStory---Saad-Pasta-from-Karachi--Pakistan-e9givv/a-a15itvo"
  ],
  display: true // Set false to hide this section, defaults to true
};

// Resume Section
const resumeSection = {
  title: "Resume",
  subtitle: "Feel free to download my resume",

  // Please Provide with Your Podcast embeded Link
  display: true // Set false to hide this section, defaults to true
};

const contactInfo = {
  title: "CONTACT ME",
  subtitle:
    "Discuss a job opportunity or a project, or just connect with me :)",
  linkedin: "https://www.linkedin.com/in/yi-c-452811132/",
 
};

// Twitter Section

const twitterDetails = {
  userName: "twitter", //Replace "twitter" with your twitter username without @
  display: true // Set true to display this section, defaults to false
};

const isHireable = false; // Set false if you are not looking for a job. Also isHireable will be display as Open for opportunities: Yes/No in the GitHub footer

export {
  illustration,
  greeting,
  socialMediaLinks,
  splashScreen,
  skillsSection,
  educationInfo,
  techStack,
  workExperiences,
  openSource,
  bigProjects,
  achievementSection,
  blogSection,
  talkSection,
  podcastSection,
  contactInfo,
  twitterDetails,
  isHireable,
  resumeSection
};
